<template>
  <v-container v-if="order">
    <CategoryTitle
      :category="category"
      v-if="
        order.orderStatusId !== 11 &&
          order.orderStatusId != 2 &&
          order.orderStatusId != 1 &&
          !paymentFailed
      "
    />
    <v-container fluid class="white pt-10">
      <v-row no-gutters>
        <v-col cols="12" md="9" lg="8">
          <v-card
            class="text-center text-sm-left secondary--text text--lighten-1 h-100"
            flat
          >
            <div
              v-if="paymentFailed"
              class="text-h1 failed text-center text-md-left font-weight-bold primary--text px-3 mt-0 mt-sm-4"
              v-html="$t('paymentCompleted.failed')"
            />
            <v-card-text v-if="paymentFailed">
              <div
                class="text-h5 text-center text-md-left"
                v-html="$t('paymentCompleted.failedDesc1')"
              />
              <h1
                color="primary"
                class="text-h1 font-weight-bold my-7 text-center"
              >
                <router-link
                  :to="{ name: 'Order', params: { orderId: order.orderId } }"
                  >{{ `N. ${order.orderId}` }}</router-link
                >
              </h1>
              <div
                class="text-h5 text-center text-md-left"
                v-html="$t('paymentCompleted.failedDesc2')"
              />
            </v-card-text>

            <v-card-text v-if="!paymentFailed">
              <div
                class="text-h1 font-weight-bold secondary--text text--lighten-1"
                v-html="$t('paymentCompleted.success')"
              />
              <v-alert type="success" class="mt-4">
                {{ $t("paymentCompleted.alertSuccess") }}
              </v-alert>
              <div
                v-if="!isLocker && !isDelivery"
                class="text-h5 mt-10"
                v-html="$t('paymentCompleted.successDesc')"
              />

              <div
                v-if="isLocker"
                class="text-h1 text-center mt-6 primary--text text--lighten1"
              >
                {{ $t("paymentCompleted.pickupCode", [lockerCode]) }}
              </div>
              <div
                color="primary"
                class="font-weight-bold mb-sm-0 text-center"
                :class="isLocker ? 'text-h2' : 'text-h1 my-7'"
              >
                <router-link
                  :to="{ name: 'Order', params: { orderId: order.orderId } }"
                  >{{
                    isLocker
                      ? `Ordine n. ${order.orderId}`
                      : `N. ${order.orderId}`
                  }}</router-link
                >
              </div>
            </v-card-text>

            <v-card-text v-if="!paymentFailed">
              <template>
                <i18n
                  path="paymentCompleted.reminder"
                  tag="div"
                  class="text-h5"
                >
                  <template v-slot:link>
                    <router-link
                      class="text-decoration-none"
                      :to="{
                        name: 'Order',
                        params: { orderId: order.orderId }
                      }"
                    >
                      <span class="font-weight-bold default--text">
                        {{ $t("paymentCompleted.ordersLink") }}</span
                      >
                    </router-link>
                  </template>
                </i18n>
              </template>
            </v-card-text>

            <div v-if="showPayment">
              <PaymentTypeList
                mode="order"
                :order-id="order.orderId"
                :options="paymentTypeListOptions"
              />
            </div>
          </v-card>
        </v-col>
        <!-- <v-col cols="0" sm="2" class="pr-8 d-none d-md-flex"> </v-col> -->
        <v-col
          cols="12"
          md="3"
          lg="4"
          class="right-column py-3 d-flex flex-column justify-start pl-3 pr-3 mr-sm-0"
        >
          <CartInfoServiceCard
            :shippingAddress="order.shippingAddress"
            :editable="false"
            class="my-2 grey lighten-3"
          />
          <CartInfoAddressCard
            :shippingAddress="order.shippingAddress"
            :editable="false"
            class="my-2 grey lighten-3"
          />
          <CartInfoTimeslotCard
            :shippingAddress="order.shippingAddress"
            :timeslot="order.timeslot"
            :editable="false"
            class="my-2 grey lighten-3"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="space-between" align="center" class="white">
        <v-col
          cols="12"
          md="9"
          lg="8"
          class="d-flex flex-column-reverse flex-sm-row justify-space-between px-3 pl-md-0 pr-md-8 mt-8 mt-md-0"
        >
          <v-btn
            text
            class="primary--text px-sm-0 mt-4 mt-sm-0"
            v-bind:to="'/'"
            large
            :outlined="$vuetify.breakpoint.xs"
            :block="$vuetify.breakpoint.xs"
          >
            <v-icon color="primary" class="d-none d-sm-flex">$prev</v-icon>
            {{ $t("paymentCompleted.goToHome") }}
          </v-btn>

          <v-btn
            v-if="paymentFailed"
            color="primary"
            depressed
            large
            :block="$vuetify.breakpoint.xs"
            @click="toggleShowPayment"
          >
            {{ $t("paymentCompleted.retry") }}
          </v-btn>

          <v-btn
            v-if="!paymentFailed"
            depressed
            v-bind:to="'/profile/orders/' + order.orderId"
            color="primary"
            large
            :block="$vuetify.breakpoint.xs"
          >
            {{ $t("paymentCompleted.goToDetail") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
a {
  text-decoration: none !important;
}
::v-deep.cart-info-card {
  height: auto;
  min-height: 120px;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .right-column {
    border-left: 2px solid var(--v-grey-lighten2);
  }
}
</style>
<script>
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import OrderService from "~/service/orderService";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";
import AnalyticsService from "~/service/analyticsService";
import { mapActions, mapGetters, mapState } from "vuex";

import get from "lodash/get";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    CartInfoServiceCard,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PaymentTypeList
  },
  data() {
    return { order: null, showPayment: false };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    },
    isLocker() {
      return this.order.shippingAddress.deliveryServiceId === 4;
    },
    isDelivery() {
      return this.order.shippingAddress.deliveryServiceId === 2;
    },
    lockerCode() {
      return get(this.order, "orderInfos.PIN_CODE", null);
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("D MMMM YYYY");
    },
    detailTimeslot() {
      return `dalle ${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} alle
        ${this.$dayjs(this.order.timeslot.endTime, [
          "h:mm:ss A",
          "H:mm"
        ]).format("HH:mm")}`;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
